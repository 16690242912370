<template>
    <div>
      <token-input @token-saved="onTokenSaved" v-if="!tokenProvided" />
      <div v-else>
        <div class="filters">
          <div class="filter-group">
            <label for="start-date">Start Date:</label>
            <input type="date" v-model="filters.startDate" @change="fetchData" />
          </div>
          <div class="filter-group">
            <label for="start-time">Start Time:</label>
            <input type="time" v-model="filters.startTime" @change="fetchData" />
          </div>
          <div class="filter-group">
            <label for="end-date">End Date:</label>
            <input type="date" v-model="filters.endDate" @change="fetchData" />
          </div>
          <div class="filter-group">
            <label for="end-time">End Time:</label>
            <input type="time" v-model="filters.endTime" @change="fetchData" />
          </div>
        </div>
  
        <button @click="downloadCSV" class="download-button">Download CSV</button>
  
        <table class="styled-table">
          <thead>
            <tr>
              <th>Serial</th>
              <th>Status</th>
              <th>Details</th>
              <th>Radio Check</th>
              <th>Model</th>
              <th>Certificate Status</th>
              <th>Setup</th>
              <th>Last Update</th>
              <th>Certificate Link</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in paginatedData" :key="item.ID">
              <td>{{ item.mac || 'N/A' }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.details }}</td>
              <td>{{ item.radioCheck ? 'Yes' : 'No' }}</td>
              <td>{{ item.model }}</td>
              <td>{{ item.certificateStatus }}</td>
              <td>{{ formatDate(item.CreatedAt) }}</td>
              <td>{{ formatDate(item.UpdatedAt) }}</td>
              <td><a :href="item.certficate" target="_blank">Download Certificate</a></td>
            </tr>
          </tbody>
        </table>
  
        <pagination-component
          :total-items="totalItems"
          :items-per-page="itemsPerPage"
          @page-changed="handlePageChange"
        />
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import TokenInput from './TokenInput.vue';
  import PaginationComponent from './PaginationComponent.vue';
  
  export default {
    components: {
      TokenInput,
      PaginationComponent,
    },
    data() {
      const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
      const startTime = '00:00';
      const endTime = '23:59';
  
      return {
        data: [],
        totalItems: 0,
        itemsPerPage: 10,
        currentPage: 1,
        tokenProvided: false,
        filters: {
          startDate: currentDate,
          startTime: startTime,
          endDate: currentDate,
          endTime: endTime,
        },
      };
    },
    computed: {
      paginatedData() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.data.slice(start, end);
      },
    },
    methods: {
      onTokenSaved() {
        this.tokenProvided = true;
        this.fetchData();
      },
      async fetchData() {
        const token = localStorage.getItem('apiToken');
        if (!token) {
          alert('Please provide a token.');
          return;
        }
  
        const { startDate, startTime, endDate, endTime } = this.filters;
        const apiUrl = `/report?startDate=${startDate}&startTime=${startTime}&endDate=${endDate}&endTime=${endTime}`;
  
        try {
          const response = await axios.get(apiUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.data = response.data;
          this.totalItems = response.data.length;
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      },
      async downloadCSV() {
        const token = localStorage.getItem('apiToken');
        if (!token) {
          alert('Please provide a token.');
          return;
        }
  
        const { startDate, startTime, endDate, endTime } = this.filters;
        const apiUrl = `/report?startDate=${startDate}&startTime=${startTime}&endDate=${endDate}&endTime=${endTime}&download=true`;
  
        try {
          const response = await axios.get(apiUrl, {
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const blob = new Blob([response.data], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'data.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading CSV:', error);
        }
      },
      handlePageChange(page) {
        this.currentPage = page;
      },
      formatDate(dateString) {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
      },
    },
    created() {
      const token = localStorage.getItem('apiToken');
      if (token) {
        this.tokenProvided = true;
        this.fetchData();
      }
    },
  };
  </script>
  
  <style scoped>
  .filters {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .filter-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .filter-group label {
    margin-bottom: 5px;
  }
  
  .filter-group input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .download-button {
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #009879;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .download-button:hover {
    background-color: #007f67;
  }
  
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  
  .styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
  }
  
  .styled-table th,
  .styled-table td {
    padding: 12px 15px;
    text-align: center; /* Center-align text in table cells */
  }
  
  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  
  .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
  }
  
  .styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
  }
  </style>
  