<template>
    <div class="token-input">
      <label for="token">Enter Token:</label>
      <input type="text" v-model="token" />
      <button @click="validateToken">Save Token</button>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        token: '',
      };
    },
    methods: {
      async validateToken() {
        try {
          const response = await axios.post('/validateToken', { token: this.token });
          if (response.data === 'Token is valid') {
            localStorage.setItem('apiToken', this.token);
            this.$emit('token-saved');
          } else {
            alert('Invalid token. Please try again.');
          }
        } catch (error) {
          console.error('Error validating token:', error);
          alert('Error validating token. Please try again.');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .token-input {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .token-input input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .token-input button {
    padding: 5px 10px;
    background-color: #009879;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .token-input button:hover {
    background-color: #007f67;
  }
  </style>
  